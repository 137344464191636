
  /* //!   SHAPE */
  
  .timestamp {
    display: block;
    font-size: 14px; 
    text-align: start;
    margin-top: 5px;
    color: #9f9f9f;
    font-weight: lighter;
  }
   
  .box3 {
    width: 300px;
    margin: 50px auto;
    border-radius: 15px;
    background: #fff;
    color: #fff;
    padding: 20px;
    text-align: center;
    font-weight: 900;
    font-family: arial;
    position: relative;
  }
  
.sb14:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 15px solid transparent;
    border-right: 15px solid #fff;
    border-top: 15px solid #fff;
    border-bottom: 15px solid transparent;
    left: -16px;
    top: 0px;
  }

  .commen-text{
    margin: 0;
    font-size: 16px;
    font-weight: normal;
    color: #000;
    text-align: start;
    line-height: 1.5;
    max-width: 100%;
    overflow: hidden ;
    white-space: normal;
    word-wrap: break-word ;
  }

  /* //! HOVER BUTTONS */

 
 

  .hover-circles { 
    display: flex; 
    flex-direction: row;
    position: absolute;
    top: 5px;
    right: 0; 
    justify-content: space-between;
    width: 170px;
    display: none;

  }
   .circle {
    width: 35px;
    height: 35px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    align-content: center;
    text-align: center; 
  }

  .chat-container:hover .hover-circles{
    display: flex;
  }
  .chat-container { 
    display: flex; 
  } 
  .chat-container:hover .box3 { 
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  } 
  .chat-container:hover .no-shadow { 
    box-shadow: rgba(50, 50, 93, 0.052) 0px 30px 60px -12px, rgba(0, 0, 0, 0.134) 0px 18px 0px -18px;
  } 
  .chat-container:hover .buttons-1 { 
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  } 



  /* //! staff */
  .staff-list {
    position: relative;
    width: 200px; /* Adjust width as needed */
    height: 200px; /* Adjust height as needed */
  }
  
  .staff-item {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .staff-item img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid white; /* Optional: add a border around images */
  }
  
  .staff-item p {
    position: absolute;
    top: 110px; /* Adjust position as needed */
    left: 0;
    width: 100px;
    text-align: center;
    font-size: 14px;
  }
  .staff-item.more {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #8A8988;
    opacity: 0.8;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid white;
  }
  
  .more-circle {
    font-size: 14px;
    font-weight: bold;
  }

  

  .staff-names {
    /* width: 60px; */
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-grow: 1;
  }
  
  .staff-name {
    color: black;
    font-weight: bold;
  }