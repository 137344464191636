/* //!   2nd design */
.button-row {
  display: flex;
  flex-direction: row;
}

.react-flow__node {
  z-index: -1 !important;
}

.rounded-button {
    width: 25px; 
    height: 25px;  
    display: flex;
    justify-content: center;
    align-items: center; 
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f5f5f5;
    cursor: pointer;
    margin-right : 5px; 
  }
  .rounded-button:hover {
    background-color: #F9F5FF;  
    border-color: #7F56D9; 
  }

.rounded-button i {
  font-size: 20px;
}

.strikethrough {
  text-decoration: line-through;
}




/* CANVAS NODE  */
.canvas_header_main_div{
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: rgba(253, 136, 147, 1); */
  justify-content:space-between;
  padding: 5px;
  margin-top: -15px;
}
.canvas_header_main_div_popUp{
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: rgba(253, 136, 147, 1); */
  justify-content:space-between;
  padding: 5px;
  /* margin-top: -15px; */
}
.canvas_header_main_div_2{
  /* display: flex; */
  /* flex-direction: row;
  align-items: center; */
  /* background-color: rgb(80, 8, 15); */
  /* justify-content:space-between; */
  /* padding: 5px; */
}

.canvas_node_header_icon_title{
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.title-Text{
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}
.title-Text-1{
  margin: 0;
  font-size: 16px;
  color: #fff;
  font-size: 20;
}
.canvas_node_content{
  margin: 10px;
}

/* //!----------- ADD ICON STYLE -------- className="add_icon_bg" */
.add_icon_main{
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 10px;
}
.add_icon_bg{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color:  rgba(253, 136, 147, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
 
}
.add_icon_bg_text{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* background-color:  rgba(253, 136, 147, 1); */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;

  margin-bottom: -15px;
  margin-right: -15px;
}

.add_icon_style{
  /* width: 20px; */
  height: 20px;
  margin: 0;
}


/* //!   in starting */
.add_icon_main_1{
  /* background-color:  rgba(253, 136, 147, 1); */
  display: flex;
  flex-direction: row; 
}

.add_icon_bg_1{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color:  rgba(253, 136, 147, 1);
  display: flex;
  justify-content: center;
  align-items: center; 
}
/* //! */
/* .grid .popover {
  border: 1px solid black;
}
.grid .popover .content {
  max-height: calc(100vh - 70px);
  overflow: auto;
} */


/* //!/ */
