.text_updater_nodecanvas {
    /* height: 50px; */
    /* border: 1px solid #eee; */
    /* padding: 5px; */
    /* border-radius: 5px; */
    background: white;
    width: 300px;
  }
.text-updater-node {
    /* border: 1px solid #eee; */
    padding: 5px;
    border-radius: 15px;
    background: white;
    width: 600px;
}
  
  .text-updater-node label {
    display: block;
    color: #777;
    font-size: 12px;
  }

  /* ! whatsapp */
  .whatsappImageclass{
    width: '15px';
    height: '15px';
    border-Radius: '5px';
    border: '1px solid #ccc';
    box-Shadow: '0 2px 5px rgba(0, 0, 0, 0.3)';
  }

  .popUp-title{
    margin: 0;
    font-size: 15px;
    font-weight: bold;
  }

  path.react-flow__edge-path {
    stroke-width: 4; 
  }
  
  path.react-flow__edge-path:hover {
    stroke-width: 8; 
  }