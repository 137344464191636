/* .audio-message {
    display: flex;
    align-items: center;
  }
  
  .play-button {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .waveform {
    width: 100%;
    height: 80px;
  }

   */
   /* styles.css */
.audio-message {
    display: flex;
    align-items: center;
  }
  
  .play-button {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .play-button:focus {
    outline: none;
  }
  